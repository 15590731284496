<template>
  <settings-layout>
    <template #actions>
      <b-button size="sm" variant="link" class="p-0 mr-1" @click="patchSetting">
        <feather-icon icon="UploadCloudIcon" size="16" class="align-middle text-body"/>
      </b-button>
    </template>

    <template #content>
      <b-alert show variant="primary" class="font-small-3">
        <b-icon-info-circle class="mr-50"/>
        These setting effect almost everything in the app.
      </b-alert>

      <template v-if="setting">
<!--        <pre>{{ JSON.parse(settingsValueString) }}</pre>-->
        <b-row class="setting-row">
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title">Current Year</div>
                <div class="setting-state">
                  <b-form-checkbox v-b-tooltip:hover="{ title: 'Option is enabled and can not be changed.' }"
                                  :checked="true"
                                   switch
                                   size="sm"
                                   :disabled="true"
                                   class="d-flex"
                                  />
                </div>
              </div>

              <div class="setting-desc">This setting sets the current year of the application.</div>
              <div class="setting-input">
                <v-select v-model="setting.value.current"
                          :options="setting.value.options.filter(option => option.enabled)"
                          :clearable="false"
                          label="title" @input="patchSetting"/>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title">Year Options</div>
                <div class="setting-state">
                    <b-button variant="primary" size="sm" class="d-flex align-items-center" @click="addYearOption">
                      <font-awesome-icon icon="fa-solid fa-plus" class="mr-50" />
                      <span>Add Year</span>
                    </b-button>
                </div>
              </div>
              <div class="setting-desc">Options for the current year selection. All enabled options will be available for Admins and Staff to select from in the page menu. </div>
              <div class="setting-sub-settings mt-1">
                <b-row>
                  <b-col v-for="(option, index) in sortedOptions" :key="index" class="mb-1 col-12 col-xxl-6 col-xxx-4">
                    <b-card bg-variant="light" class="border shadow-none mb-0" :class="cardBorder(option)">
                      <b-card-title class="d-flex justify-content-between align-items-center">
                        <div class="setting-title">{{ option.title }}</div>
                        <div class="setting-state min-height-24">
                          <template v-if="!isCurrent(option)">
                            <b-form-checkbox v-model="option.enabled"
                                             v-b-tooltip:hover="{ title: `Option is ${option.enabled ? 'enabled' : 'disabled'}` }"
                                             switch size="sm"
                                             class="d-flex align-items-center pb-25"
                                             @change="patchSetting"/>
                            <b-button v-b-modal="`confirm-${option.id}`" v-b-tooltip:hover="{ title: 'Remove Option' }" :disabled="option.enabled" variant="link" class="p-25 ml-50">
                              <b-icon-x-circle-fill variant="danger"/>
                            </b-button>
                            <b-modal :id="`confirm-${option.id}`" centered title="Confirm" @ok="removeOption(option)">
                              <template #default>
                                <div class="d-flex align-items-center">
                                  <b-icon-exclamation-circle-fill variant="danger" class="mr-1"/>
                                  <span>Are you sure you want to remove this option?</span>
                                </div>
                              </template>
<!--                              <template #modal-footer>
                                <b-button variant="danger" @click="removeOption(option)">Remove</b-button>
                                <b-button variant="outline-secondary" @click="confirmOpen = false">Cancel</b-button>
                              </template>-->
                            </b-modal>

                          </template>
                          <template v-else>
                            <b-badge v-b-tooltip:hover="{ title: 'Option is selected as Current Year.' }" variant="primary">Current</b-badge>
                          </template>
                        </div>
                      </b-card-title>
                      <b-card-text>
                        <b-form-group label="Title">
                          <b-input v-model="option.title" type="text"></b-input>
                        </b-form-group>

                        <b-form-group label="Start Date">
                          <b-datepicker v-model="option.year.start" hide-header nav-button-variant="primary" @input="patchSetting"/>
<!--                          <b-input-group class="input-group-merge bg-white input-group-border">
                            <b-input-group-prepend>
                              <b-button size="sm" variant="transparent" title="Toggle" data-toggle>
                                <font-awesome-icon icon="fa-solid fa-calendar" />
                                <span aria-hidden="true" class="sr-only">Toggle</span>
                              </b-button>
                            </b-input-group-prepend>

                            <flat-pickr
                                v-model="option.year.start"
                                :config="flatPickrConfig.start"
                                class="form-control bg-white"
                                placeholder="Select date"
                                name="date"/>
&lt;!&ndash;                            <b-input-group-append>
                              <b-button size="sm" variant="transparent" title="Clear" data-clear>
                                <font-awesome-icon icon="fa-solid fa-times" />
                                <span aria-hidden="true" class="sr-only">Clear</span>
                              </b-button>
                            </b-input-group-append>&ndash;&gt;
                          </b-input-group>-->
                        </b-form-group>

                        <b-form-group label="End Date">
                          <b-datepicker v-model="option.year.end" hide-header nav-button-variant="primary" @input="patchSetting"/>
<!--                          <b-input-group class="input-group-merge bg-white input-group-border">
                            <b-input-group-prepend>
                              <b-button size="sm" variant="transparent" title="Toggle" data-toggle>
                                <font-awesome-icon icon="fa-solid fa-calendar" />
                                <span aria-hidden="true" class="sr-only">Toggle</span>
                              </b-button>
                            </b-input-group-prepend>
                            <flat-pickr
                                v-model="option.year.end"
                                :config="flatPickrConfig.end"
                                class="form-control bg-white"
                                placeholder="Select date"
                                name="date"/>
&lt;!&ndash;                            <b-input-group-append>
                              <b-button size="sm" variant="transparent" title="Clear" data-clear>
                                <font-awesome-icon icon="fa-solid fa-times" />
                                <span aria-hidden="true" class="sr-only">Clear</span>
                              </b-button>
                            </b-input-group-append>&ndash;&gt;
                          </b-input-group>-->
                        </b-form-group>
                      </b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
    </template>

    <template #debug>
      <debug title="setting" collapsed class="mt-2">{{ setting }}</debug>
    </template>
  </settings-layout>
</template>


<script>
import {API, graphqlOperation} from 'aws-amplify';
import {getSetting} from '@/graphql/queries';
import {updateSetting} from '@/graphql/mutations';
import notify from '@/mixins/notify.mixin';
import vSelect from 'vue-select';
import SettingsLayout from '@/views/management/settings/SettingsLayout.vue';
import popper from '@/mixins/popper.mixin';
import {BDropdownItem} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import {uuid} from 'vue-uuid';

export default {
  name: 'SettingsApp',
  components: {
    BDropdownItem,
    SettingsLayout,
    vSelect,
    flatPickr
  },
  mixins: [ notify, popper ],
  data() {
    return {
      key: 'app',
      setting: null,
      icon: 'fas fa-gear',
      flatPickrConfig: {
        start: {
          wrap: true, // set wrap to true only when using 'input-group'
          //altFormat: 'l, F d, Y',
          altFormat: 'Z',
          altInput: true,
          enableTime: true,
          enableSeconds: true,
          defaultHour: 0,
          defaultMinute: 0,
          dateFormat: 'Z',
          altInputClass: 'form-control bg-white border-0',
        },
        end: {
          wrap: true, // set wrap to true only when using 'input-group'
          //altFormat: 'l, F d, Y',
          altFormat: 'Z',
          altInput: true,
          enableTime: true,
          enableSeconds: true,
          defaultHour: 23,
          defaultMinute: 59,
          dateFormat: 'Z',
          altInputClass: 'form-control bg-white border-0',
        }
      },
      popperSettings: {
        placement: 'bottom'
      },
      confirmOpen: false
    }
  },
  computed: {
    sortedOptions() {
      if(!this.setting) return []
      return this.setting.value.options.toSorted((a, b) => new Date(b.year.start) - new Date(a.year.start))
    },
    settingsValueString() {
      if(!this.setting) return '{}';
      const formattedValue = { ...this.setting.value };

      if(formattedValue.options) {
        formattedValue.options = formattedValue.options.map(option => {
          const formattedOption = { ...option };
          if (formattedOption.year) {
            formattedOption.year.start = `${formattedOption.year.start.split('T')[0]}T00:00:00.000Z`;
            formattedOption.year.end = `${formattedOption.year.end.split('T')[0]}T23:59:59.999Z`;
          }
          delete formattedOption.isNew; // Remove the isNew field
          return formattedOption;
        });
      }
      if(formattedValue.current) {
        formattedValue.current = formattedValue.options.find(option => option.id === formattedValue.current.id)
      }
      return JSON.stringify(formattedValue);
    }
  },
  async mounted() {
    await this.getSetting()
  },
  methods: {
    async getSetting() {
      const response = await API.graphql(graphqlOperation(getSetting, { key: this.key }));
      this.setting = response.data.getSetting
      if(this.setting) {
        this.setting.value = JSON.parse(this.setting.value)
      }
    },
    async patchSetting() {
      try {
        const settingInput = {
          key: this.key,
          value: this.settingsValueString
        }

        const response = await API.graphql(graphqlOperation(updateSetting, { input: settingInput }));
        this.setting = response.data.updateSetting;
        if(this.setting) {
          this.setting.value = JSON.parse(this.setting.value)
          this.$store.commit('settings/setSettingsAppOptions', this.setting.value.options)
        }
        this.notify({ title: 'Success', text: 'Setting was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Setting failed to update', icon: this.icon, variant: 'danger'});
      }
    },

    cardBorder(option) {
      if(this.isCurrent(option)) return 'border-primary'
      if(option.isNew) return 'border-success'
      return ''
    },

    addYearOption() {
      const currentYear = new Date().getFullYear()
      this.setting.value.options.push({
        id: uuid.v4(),
        title: `New Option ${this.setting.value.options.length + 1}`,
        enabled: false,
        year: {
          start: `${currentYear + 1}-01-01T00:00:00.000Z`,
          end: `${currentYear + 1}-12-31T23:59:59.999Z`
        },
        isNew: true
      })
      this.patchSetting()
    },


    removeOption(option) {
      this.setting.value.options = this.setting.value.options.filter(o => o.id !== option.id)
      this.patchSetting()
    },

    isCurrent(option) {
      return this.setting.value.current.id === option.id
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/nyssma/settings.scss';
  @import '~@core/scss/vue/libs/vue-flatpicker.scss';

  html[dir='rtl'] {
    .flatpickr-calendar {
      .flatpickr-time {
        display: none!important;
        height: 0!important;
      }
    }
  }

  .min-height-24 {
    min-height: 24px;
  }

  .input-group-border {
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
  }

  .input-group:focus-within {
    border-color: var(--primary);
  }

</style>
